import React from 'react'
import ReactDOM from 'react-dom'
import './index.css'
import App from './App'
import ReactGA from 'react-ga'

ReactDOM.render(<App />, document.getElementById('root'))

ReactGA.initialize('UA-135781488-1', { debug: window.location.hostname === 'localhost' })
ReactGA.pageview(window.location.pathname + window.location.search)
