import React from 'react'

export default function Footer() {
  return (
    <footer id='main-footer' className='bg-dark text-center py-1'>
      <div className='container'>
        <p>Copyright &copy; 2022, Solar Bear</p>
      </div>
    </footer>
  )
}
