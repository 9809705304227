import { scroller } from 'react-scroll';
import ReactGA from 'react-ga';

export default function scroll(event, name) {
  event.preventDefault()

  scroller.scrollTo(name, { 
    smooth: true,
    duration: 1500,
    delay: 100,
    offset: -65,
  })

  ReactGA.pageview(window.location.pathname + '#' + name);
}
