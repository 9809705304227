import React, { Component } from 'react';
import './App.css'
import { Navbar, Header, Products, ContactForm, CompanyInfo, Footer } from './components';

class App extends Component {
  render() {
    return (
      <div className="App" id="home" name='home'>
        <Navbar />
        <Header />
        <Products />
        <ContactForm />
        <CompanyInfo />
        <Footer />
      </div>
    );
  }
}

export default App;
