import React from 'react'
import ReactGA from 'react-ga'

export default class ContactForm extends React.Component {
  constructor() {
    super()
    this.state = { name: '', email: '', message: '' }
  }

  sendForm(event) {
    event.preventDefault()

    fetch('/api/message', {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(this.state),
    }).then((_response) => {
      ReactGA.event({
        category: 'Contact Form',
        action: 'Sent',
      })

      this.setState({ name: '', email: '', message: '' })
      alert('Message sent!')
    })
  }

  render() {
    return (
      <section id='contact' name='contact'>
        <div className='contact-form bg-primary p-2'>
          <h2 className='m-heading'>Contact Us</h2>
          <p>Please use the form below to contact us</p>
          <form onSubmit={(e) => this.sendForm(e)}>
            <div className='form-group'>
              <label htmlFor='name'>Name</label>
              <input
                onChange={(e) => this.setState({ name: e.target.value })}
                value={this.state.name}
                type='text'
                name='name'
                id='name'
                placeholder='Enter Name'
                required={true}
              />
            </div>
            <div className='form-group'>
              <label htmlFor='email'>Email</label>
              <input
                onChange={(e) => this.setState({ email: e.target.value })}
                value={this.state.email}
                type='email'
                name='email'
                id='email'
                placeholder='Enter Email'
                required={true}
              />
            </div>
            <div className='form-group'>
              <label htmlFor='message'>Message</label>
              <textarea
                onChange={(e) => this.setState({ message: e.target.value })}
                value={this.state.message}
                name='message'
                id='message'
                placeholder='Enter Message'
                required={true}
              />
            </div>
            <input type='submit' value='Send' className='btn btn-dark' />
          </form>
        </div>
      </section>
    )
  }
}
