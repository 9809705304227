import React from 'react';

export default function Products() {
  return (
    <section id="products" name='products'>
      <div className="products-img"></div>
      <div className="products-text bg-dark p-2">
        <h2 className="m-heading"><span className="text-primary">Products</span></h2>
        <p>Solar Bear provides home and office products via Amazon Marketplace.</p>
        <h3>Current listings:</h3>
        <ul className="list">
          <li>Acrylic Organiser, Color: Clear Transparent</li>
          <li>Acrylic Organiser, Color: Clear Blue</li>
        </ul>
      </div>
    </section>
  )
}
