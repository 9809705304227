import React from 'react'

export default function CompanyInfo() {
  return (
    <section id='company-info'>
      <div className='contact-text bg-dark p-2'>
        <div className='address'>
          <p>Solar Bear LLC</p>
          <p>Redwood City, California, USA</p>
        </div>
      </div>
    </section>
  )
}
