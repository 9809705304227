import React from 'react';
import scroll from '../common/scroll';

export default function Navbar() {
  return (
    <nav id="navbar">
      <h1 className="logo">
        <span className="text-primary">Solar Bear</span>
      </h1>
      <ul>
        <li><a href="#home" onClick={e => scroll(e, 'home')}>Home</a></li>
        <li><a href="#products" onClick={e => scroll(e, 'products')}>Products</a></li>
        <li><a href="#contact" onClick={e => scroll(e, 'contact')}>Contact</a></li>
      </ul>
    </nav>
  )
}
