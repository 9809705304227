import React from 'react';
import scroll from '../common/scroll';

export default function Header() {
  return (
    <header id="showcase">
      <div className="showcase-content">
        <h1 className="l-heading">
          Welcome
        </h1>
        <p className="lead">
          Solar Bear Company povides home and office products
          <br />
          such as storage and organization items.
        </p>
        <a href="#products" className="btn" onClick={e => scroll(e, 'products')}>Check Out Products</a>
      </div>
    </header>
  )
}
